@import '~@styles/tokens/index.scss';

#globalHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  background: silver;
  padding: $spacing-lg;
  padding: 10vh;
  margin-bottom: $spacing-lg;

  div {
    flex-basis: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
  }
}